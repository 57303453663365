body{
  font-family: 'Poppins', sans-serif;
}

html, body, #root, #root > div, .App, .App > main {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

html, body {
  position: fixed;
}

html, body {
  position: relative;
  overflow: hidden;
}

body {
  position: fixed;
  overflow: hidden;
}

body {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
}